body {
    margin: 0 !important;
    margin-top: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-justify {
    text-align: justify;
}

.flip-x {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}
